export interface OfficeStaff {
  id: number;
  agreement_id: number;
  office_id: number;
  ewell_user_id: number;
  is_exists_key: number;
  send_tel_no: string;
  send_mail_address: string;
  send_user_id: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  info: StaffHistory;
  // StaffKey key
  // ApplyOffice office
  ewell_user: ApplyEwellUser;
}

export interface StaffHistory {
  id: number;
  staff_id: number;
  start_date: string;
  end_date: string;
  office_id: number;
  ewell_user_id: number;
  is_link_ewell_user_id: number;
  ibow_nickname: string;
  ibow_nickname_furigana: string;
  office_staff_id: string;
  office_mobile_area: string;
  office_mobile_city: string;
  office_mobile_num: string;
  mobile_area: string;
  mobile_city: string;
  mobile_num: string;
  status: number;
  is_check_agreement: number;
  auth_id: number;
  staff_div_id: number;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  staff_div_name: string;
  visitable: number;
  visitable_mental: number;
  accompanable: number;
  accompanable_mental: number;
  receipt_flg: number;
  vnplan_flg: number;
  start_date_wareki: string;
  end_date_wareki: string;
  auth_name: string;
  // repeated AuthFunction auth_functions :
}

export interface ApplyEwellUser {
  /** ID */
  id: number;
  /** iBowID  */
  user_id: string;
  /** メールアドレス */
  mail_address: string;
  /** 電話番号 */
  tel_no: string;
  /** システムID */
  system_id: string;
  /** 姓 */
  family_name: string;
  /** 名 */
  first_name: string;
  /** 姓(ふりがな) */
  family_name_furigana: string;
  /** 名(ふりがな) */
  first_name_furigana: string;
}

export const DEFAULT_APPLY_EWELL_USER: ApplyEwellUser = {
  id: 0,
  user_id: "",
  mail_address: "",
  tel_no: "",
  system_id: "",
  family_name: "",
  first_name: "",
  family_name_furigana: "",
  first_name_furigana: "",
};
