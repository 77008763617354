






























































import { Component, Mixins } from "vue-property-decorator";
import { DEFAULT_INVOICE_NOTICE } from "#/model/bulletinboard";
import { InvoiceNoticeItem } from "@/views/invoicenotice/types";
import InvoiceNoticeDetailDialog from "@/components/invoicenotice/InvoiceNoticeDetailDialog.vue";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";

@Component({
  components: {
    InvoiceNoticeDetailDialog,
  },
})
export default class EndList extends Mixins(AxiosMixin, UtilMixin) {
  /** 変数 */

  /** 請求お知らせ */
  private invoiceNotices: InvoiceNoticeItem[] = [];

  /** データテーブル用ヘッダー */
  private headers = [
    {
      text: "",
      align: "start",
      value: "actions",
      width: "3rem",
      filterable: false,
      sortable: false,
    },
    {
      text: "掲載期間",
      align: "start",
      value: "period",
      width: "330px",
      filterable: false,
      sortable: false,
    },
    {
      text: "件名",
      align: "start",
      value: "title",
      filterable: true,
      sortable: false,
    },
  ];

  /** 1ページあたりの表示件数 */
  private itemPerPage = 25;

  /** 表の現在のページ数 */
  private pageNo = 1;

  /** 詳細ダイアログ表示制御 */
  private showDialog = false;

  /** 選択中の請求お知らせ */
  private selectedInvoiceNotice: InvoiceNoticeItem = {
    ...DEFAULT_INVOICE_NOTICE,
    editor_name: "",
  };

  /** フィルター用テキスト */
  private searchKeyword = "";
  /** フィルター条件:掲載開始日 */
  private searchStartDate = "";
  /** フィルター条件:掲載終了日 */
  private searchEndDate = "";

  /** フィルター後請求お知らせ */
  private get FilteredItems() {
    if (!this.searchKeyword && !this.searchStartDate && !this.searchEndDate) {
      return this.invoiceNotices;
    }

    return this.invoiceNotices.filter((invoiceNotice) => {
      return (
        (invoiceNotice.title.includes(this.searchKeyword) ||
          invoiceNotice.content.includes(this.searchKeyword)) &&
        (this.searchEndDate
          ? invoiceNotice.start_date <= this.searchEndDate
          : true) &&
        (this.searchStartDate
          ? invoiceNotice.end_date >= this.searchStartDate
          : true)
      );
    });
  }

  /** 関数 */

  created(): void {
    this.fetchInvoiceNotice();
  }

  /** 請求お知らせ取得 */
  private fetchInvoiceNotice(): void {
    this.postJsonCheck(
      window.base_url + "/api/admin/invoicenotice/end/get",
      {
        date_div: 1,
      },
      (res) => {
        this.invoiceNotices = res.data.invoice_notices;
      }
    );
  }

  /** 詳細ボタンクリック */
  private openDetail(item: InvoiceNoticeItem) {
    this.showDialog = true;
    this.selectedInvoiceNotice = item;
  }

  /** 編集 */
  private editInvoiceNotice(invoiceNotice: InvoiceNoticeItem) {
    this.$router.push({ path: "/invoicenotice/edit/" + invoiceNotice.id });
  }

  /** データテーブルのページ変更 */
  private changePage(pageNo: number) {
    this.pageNo = pageNo;
  }
}
