

























































import { Component, Mixins, Prop, Emit } from "vue-property-decorator";
import { DEFAULT_INVOICE_NOTICE } from "#/model/bulletinboard";
import { STATUS } from "@/views/invoicenotice/consts";
import { InvoiceNoticeItem } from "@/views/invoicenotice/types";
import AppAuthButton from "@/components/admin_common/AppAuthButton.vue";
import UtilMixin from "@/mixins/utilMixin";

@Component({
  components: {
    AppAuthButton,
  },
})
export default class InvoiceNoticeDetailDialog extends Mixins(UtilMixin) {
  /** Prop */

  /** 請求お知らせ */
  @Prop({ default: () => DEFAULT_INVOICE_NOTICE })
  invoiceNotice!: InvoiceNoticeItem;

  /** ダイアログ表示制御 */
  @Prop({ default: () => false }) value!: boolean;

  /** 画面情報 1:公開前／公開中一覧 2:公開終了 */
  @Prop({ default: () => 0 }) fromType!: number;

  /** 変数 */

  /** 状態定数 */
  private STATUS = STATUS;

  /** getter */

  /** ダイアログ表示制御 */
  private get Value(): boolean {
    return this.value;
  }

  /** setter */

  /** ダイアログ表示制御 */
  private set Value(newValue: boolean) {
    this.input(newValue);
  }

  /** ダイアログキャンセル */
  private cancel() {
    this.Value = false;
  }

  /** 関数 */
  private async beforeFinishInvoiceNotice(invoiceNotice: InvoiceNoticeItem) {
    if (!(await this.$openConfirm("早期終了しますか？"))) {
      return;
    }
    this.finishInvoiceNotice(invoiceNotice);
  }

  /** Emit */

  /** 更新 */
  @Emit()
  private input(newValue: boolean): boolean {
    return newValue;
  }

  /** 編集 */
  @Emit("edit:notice")
  private editInvoiceNotice(invoiceNotice: InvoiceNoticeItem) {
    this.Value = false;
    return invoiceNotice;
  }

  /** 早期終了 */
  @Emit("finish:notice")
  private finishInvoiceNotice(invoiceNotice: InvoiceNoticeItem) {
    this.Value = false;
    return invoiceNotice;
  }
}
