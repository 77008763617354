import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

import Top from "@/views/Top.vue";
import Current from "@/views/information/Current.vue";
import End from "@/views/information/End.vue";
import Form from "@/views/information/Form.vue";
import InProgress from "@/views/inquiry/InProgress.vue";
import InquiryCompleted from "@/views/inquiry/InquiryCompleted.vue";
import InquiryShow from "@/views/inquiry/show/InquiryShow.vue";
import InquiryEdit from "@/views/inquiry/edit/InquiryEdit.vue";
import InquiryAnswerEdit from "@/views/inquiry/answer/InquiryAnswerEdit.vue";
import TemplateList from "@/views/inquiry/template/TemplateList.vue";
import TemplateEdit from "@/views/inquiry/template/TemplateEdit.vue";
import RecommendKeywordEdit from "@/views/inquiry/recommendKeyword/RecommendKeywordEdit.vue";
import IbowHelpSort from "@/views/inquiry/sort/IbowHelpSort.vue";
import ContractList from "@/views/contractmanagement/ContractList.vue";
import ContractDetail from "@/views/contractmanagement/ContractDetail.vue";
import ContractEdit from "@/views/contractmanagement/ContractEdit.vue";
import AgreementContractHistory from "@/views/contractmanagement/AgreementContractHistory.vue";
import OfficeEdit from "@/views/contractmanagement/OfficeEdit.vue";
import OfficeContractHistory from "@/views/contractmanagement/OfficeContractHistory.vue";
import PortalSelectOfficeList from "@/views/contractmanagement/PortalSelectOfficeList.vue";
import HonobonoOfficeList from "@/views/honobonosetting/HonobonoOfficeList.vue";
import HonobonoOfficeEdit from "@/views/honobonosetting/HonobonoOfficeEdit.vue";
import ContractReceptionList from "@/views/contractreception/ContractReceptionList.vue";
import ContractReceptionDetail from "@/views/contractreception/ContractReceptionDetail.vue";
import UserList from "@/views/user/UserList.vue";
import Profile from "@/views/user/Profile.vue";
import Auth from "@/views/user/Auth.vue";
import AuthEdit from "@/views/user/AuthEdit.vue";
import MaintenanceNotification from "@/views/maintenance/MaintenanceNotification.vue";
import FinishMaintenance from "@/views/maintenance/FinishMaintenance.vue";
import MaintenanceEdit from "@/views/maintenance/MaintenanceEdit.vue";
import CurrentResearch from "@/views/research/CurrentResearch.vue";
import EndResearch from "@/views/research/EndResearch.vue";
import EditResearch from "@/views/research/EditResearch.vue";
import ConfigurationShow from "@/views/configuration/ConfigurationShow.vue";
import ConfigurationExport from "@/views/configuration/ConfigurationExport.vue";
import ConfigurationViewer from "@/views/configuration/ConfigurationViewer.vue";
import Login from "@/views/ibowlogin/Login.vue";
import Spoofing from "@/views/ibowlogin/Spoofing.vue";
import OperationLog from "@/views/operationlog/OperationLog.vue";
import AdminOperationLog from "@/views/operationlog/AdminOperationLog.vue";
import InputDemosite from "@/views/demosite/InputDemosite.vue";
import ReleaseStaffLink from "@/views/stafflink/ReleaseStaffLink.vue";
import IbowAccountList from "@/views/ibowaccount/IbowAccountList.vue";
import IbowAccountDetail from "@/views/ibowaccount/IbowAccountDetail.vue";
import IbowAccountEdit from "@/views/ibowaccount/IbowAccountEdit.vue";
import IbowAccountDeletedList from "@/views/ibowaccount/IbowAccountDeletedList.vue";
import IbowAccountStoppedList from "@/views/ibowaccount/IbowAccountStoppedList.vue";
import DatasetList from "@/views/aivnplan/DatasetList.vue";
import DatasetForm from "@/views/aivnplan/DatasetForm.vue";
import IndexConfigure from "@/views/aivnplan/IndexConfigure.vue";
import PromptTemplateList from "@/views/aivnplan/PromptTemplateList.vue";
import PromptTemplateForm from "@/views/aivnplan/PromptTemplateForm.vue";
import Configuration from "@/views/aivnplan/Configuration.vue";
import TermList from "@/views/recommend/TermList.vue";
import TermEdit from "@/views/recommend/TermEdit.vue";
import ServiceLinkList from "@/views/serviceLink/list.vue";
import ServiceLinkEdit from "@/views/serviceLink/edit.vue";
import ServiceLinkSort from "@/views/serviceLink/sort.vue";
import ServiceLinkCategory from "@/views/serviceLink/category.vue";
import ServiceLinkMailLog from "@/views/serviceLink/mailLog.vue";
import AiMonthReportPromptTemplateList from "@/views/aimonthreport/AiMonthReportPromptTemplateList.vue";
import AiMonthReportPromptTemplateEdit from "@/views/aimonthreport/AiMonthReportPromptTemplateEdit.vue";
import PortalStaffAgentLogin from "@/views/portallogin/PortalStaffAgentLogin.vue";
import PortalRequestUserAgentLogin from "@/views/portallogin/PortalRequestUserAgentLogin.vue";
import PortalMailLog from "@/views/portal/PortalMailLog.vue";
import PortalOfficialAnnouncementList from "@/views/portal/PortalOfficialAnnouncementList.vue";
import PortalOfficialAnnouncementEdit from "@/views/portal/PortalOfficialAnnouncementEdit.vue";
import PortalOfficeList from "@/views/portal/PortalOfficeList.vue";
import PortalOfficeUnapprovedList from "@/views/portal/PortalOfficeUnapprovedList.vue";
import PortalOfficeIbowDetail from "@/views/portal/PortalOfficeIbowDetail.vue";
import PortalOfficeNoIbowDetail from "@/views/portal/PortalOfficeNoIbowDetail.vue";

import PortalOfficeNoIbowEdit from "@/views/portal/PortalOfficeNoIbowEdit.vue";
import PortalAgreementNoIbowEdit from "@/views/portal/PortalAgreementNoIbowEdit.vue";
import PortalOfficeIbowEdit from "@/views/portal/PortalOfficeIbowEdit.vue";

import PortalOrganizationList from "@/views/portal/PortalOrganizationList.vue";
import PortalOrganizationUnapprovedList from "@/views/portal/PortalOrganizationUnapprovedList.vue";
import PortalOrganizationDetail from "@/views/portal/PortalOrganizationDetail.vue";
import PortalOrganizationEdit from "@/views/portal/PortalOrganizationEdit.vue";

import InvoiceNoticeCurrentList from "@/views/invoicenotice/CurrentList.vue";
import InvoiceNoticeEndList from "@/views/invoicenotice/EndList.vue";
import InvoiceNoticeForm from "@/views/invoicenotice/Form.vue";

Vue.use(VueRouter);

// authNameは閲覧権限チェック用の値
// is_edit_pageは編集権限がない場合にアクセスができないようにするための値
// -> falseであれば編集権限がなくても閲覧はできる
// -> trueであれば編集権限がないと閲覧できない
// 例：編集権限がない場合に新規作成画面にアクセスしてほしくない場合

const routes: Array<RouteConfig> = [
  //空ページ
  {
    path: "/",
    component: Top,
    meta: { authName: "/" },
  },
  /**
   * お知らせ管理
   *
   * 公開前/公開中
   * 公開終了
   * 新規作成
   */
  //お知らせ 公開前／公開中
  {
    path: "/information/current",
    component: Current,
    meta: { authName: "/information", is_edit_page: false },
  },
  //お知らせ 公開終了
  {
    path: "/information/end",
    component: End,
    meta: { authName: "/information", is_edit_page: false },
  },
  //お知らせ 新規作成
  {
    path: "/information/form/:id",
    component: Form,
    meta: { authName: "/information", is_edit_page: true },
  },
  /**
   * サポート管理
   *
   * 問い合わせ進行中
   * 問い合わせ完了
   * 新規作成
   * FAQテンプレート
   *
   * 問い合わせ詳細
   * 回答編集
   * FAQテンプレート詳細
   */
  //問い合わせ 進行中一覧
  {
    path: "/inquiry/inprogress",
    component: InProgress,
    meta: { authName: "/inquiry", is_edit_page: false },
  },
  //問い合わせ 問い合わせ完了一覧
  {
    path: "/inquiry/completed",
    component: InquiryCompleted,
    meta: { authName: "/inquiry", is_edit_page: false },
  },
  //問い合わせ 問い合わせ詳細
  {
    path: "/inquiry/show/:id",
    component: InquiryShow,
    meta: { authName: "/inquiry", is_edit_page: false },
  },
  //問い合わせ 問い合わせ編集
  {
    path: "/inquiry/:parentId/edit/:id?",
    component: InquiryEdit,
    meta: { authName: "/inquiry", is_edit_page: true },
  },
  //問い合わせ 回答編集
  {
    path: "/inquiry/:parentInqId/answer/edit/:inqId/:ansId",
    component: InquiryAnswerEdit,
    meta: { authName: "/inquiry", is_edit_page: true },
  },
  //FAQテンプレート 一覧
  {
    path: "/template",
    component: TemplateList,
    meta: { authName: "/inquiry", is_edit_page: false },
  },
  //FAQテンプレート 編集
  {
    path: "/template/edit/:id",
    component: TemplateEdit,
    meta: { authName: "/inquiry", is_edit_page: true },
  },
  //FAQおすすめキーワード 編集
  {
    path: "/template/recommendkeyword",
    component: RecommendKeywordEdit,
    meta: { authName: "/inquiry", is_edit_page: true },
  },
  //iBowヘルプ 並び替え
  {
    path: "/template/sort",
    component: IbowHelpSort,
    meta: { authName: "/inquiry", is_edit_page: false },
  },
  /**
   * 契約管理
   *
   * 企業管理企業
   * 契約未確定企業
   *
   * 企業情報詳細
   * 企業情報編集
   * 企業情報変更履歴
   * 事業所情報編集
   * 事業所履歴
   * ほのぼのNext設定 事業所一覧
   * ほのぼのNext設定 事業所編集
   * 契約受付詳細
   */
  //契約管理 企業一覧
  {
    path: "/contractmanagement/list",
    component: ContractList,
    meta: { authName: "/contract", is_edit_page: false },
  },
  //契約管理 企業情報詳細
  {
    path: "/contractmanagement/detail/:id",
    component: ContractDetail,
    meta: { authName: "/contract", is_edit_page: false },
  },
  //契約管理 企業情報編集
  {
    path: "/contractmanagement/edit/:id",
    component: ContractEdit,
    meta: { authName: "/contract", is_edit_page: true },
  },
  //契約管理 企業情報変更履歴
  {
    path: "/agreement/history/list/:id",
    component: AgreementContractHistory,
    meta: { authName: "/contract", is_edit_page: false },
  },
  //契約管理 事業所情報編集
  {
    path: "/office/edit/:id",
    component: OfficeEdit,
    meta: { authName: "/contract", is_edit_page: true },
  },
  //契約管理 事業所履歴
  {
    path: "/office/history/list/:id",
    component: OfficeContractHistory,
    meta: { authName: "/contract", is_edit_page: false },
  },
  //契約管理 ほのぼのNext設定 事業所一覧
  {
    path: "/honobono/office/list/:id",
    component: HonobonoOfficeList,
    meta: { authName: "/contract", is_edit_page: false },
  },
  //契約管理 ほのぼのNext設定 事業所編集
  {
    path: "/honobono/office/edit/:id",
    component: HonobonoOfficeEdit,
    meta: { authName: "/contract", is_edit_page: true },
  },
  //契約管理 契約未確定企業
  {
    path: "/contractreception/list",
    component: ContractReceptionList,
    meta: { authName: "/contract", is_edit_page: false },
  },
  //契約管理 契約受付詳細
  {
    path: "/contractreception/detail/:id",
    component: ContractReceptionDetail,
    meta: { authName: "/contract", is_edit_page: false },
  },
  //契約管理 ポータル 事業所一覧
  {
    path: "/portal/select/office/list/:agreement_id/:id",
    component: PortalSelectOfficeList,
    meta: { authName: "/contract", is_edit_page: true },
  },
  //ユーザ一覧
  {
    path: "/user/list",
    component: UserList,
    meta: { authName: "/user", is_edit_page: false },
  },
  //ユーザ編集
  {
    path: "/user/profile/:id",
    component: Profile,
    meta: { authName: "/user", is_edit_page: true },
  },
  //ユーザ権限一覧
  {
    path: "/auth/list",
    component: Auth,
    meta: { authName: "/user", is_edit_page: false },
  },
  //ユーザ権限編集
  {
    path: "/auth/edit/:id",
    component: AuthEdit,
    meta: { authName: "/user", is_edit_page: true },
  },
  //メンテ管理 公開前/公開中
  {
    path: "/maintenance/notification",
    component: MaintenanceNotification,
    meta: { authName: "/maintenance", is_edit_page: false },
  },
  //メンテ管理 公開終了
  {
    path: "/maintenance/finish",
    component: FinishMaintenance,
    meta: { authName: "/maintenance", is_edit_page: false },
  },
  //メンテ管理 編集
  {
    path: "/maintenance/edit/:id",
    component: MaintenanceEdit,
    meta: { authName: "/maintenance", is_edit_page: true },
  },
  //アンケート管理 公開前/公開中
  {
    path: "/research/current",
    component: CurrentResearch,
    meta: { authName: "/research", is_edit_page: false },
  },
  //アンケート管理 公開終了
  {
    path: "/research/end",
    component: EndResearch,
    meta: { authName: "/research", is_edit_page: false },
  },
  //アンケート管理 編集
  {
    path: "/research/edit/:id",
    component: EditResearch,
    meta: { authName: "/research", is_edit_page: true },
  },
  //基本設定
  {
    path: "/configuration/show",
    component: ConfigurationShow,
    meta: { authName: "/configuration", is_edit_page: false },
  },
  //エクスポート
  {
    path: "/configuration/export",
    component: ConfigurationExport,
    meta: { authName: "/configuration", is_edit_page: false },
  },
  //集計情報ビュアー
  {
    path: "/configuration/viewer",
    component: ConfigurationViewer,
    meta: { authName: "/configuration", is_edit_page: false },
  },
  //iBowログイン
  {
    path: "/ibowlogin/login",
    name: "IbowLoginLogin",
    component: Login,
    meta: { authName: "/ibowlogin", is_edit_page: false },
  },
  //iBowユーザとしてログイン
  {
    path: "/ibowlogin/spoofing",
    name: "IbowLoginSpoofing",
    component: Spoofing,
    meta: { authName: "/ibowlogin", is_edit_page: false },
  },
  //オペレーションログ
  {
    path: "/operationlog/show",
    name: "OperationLog",
    component: OperationLog,
    meta: { authName: "/operationlog", is_edit_page: false },
  },
  //管理者用オペレーションログ
  {
    path: "/admin/operationlog/show",
    name: "AdminOperationLog",
    component: AdminOperationLog,
    meta: { authName: "/operationlog", is_edit_page: false },
  },
  //iBowアカウント管理
  {
    path: "/ibowaccount/list",
    name: "IbowAccountList",
    component: IbowAccountList,
    meta: { authName: "/ibowaccount", is_edit_page: false },
  },
  //iBowアカウント詳細
  {
    path: "/ibowaccount/detail/:id",
    component: IbowAccountDetail,
    meta: { authName: "/ibowaccount", is_edit_page: false },
  },
  //iBowアカウント編集
  {
    path: "/ibowaccount/edit/:id",
    component: IbowAccountEdit,
    meta: { authName: "/ibowaccount", is_edit_page: false },
  },
  //iBowアカウント削除済み一覧
  {
    path: "/ibowaccount/deleted_list",
    component: IbowAccountDeletedList,
    meta: { authName: "/ibowaccount", is_edit_page: false },
  },
  //iBowアカウント停止中一覧
  {
    path: "/ibowaccount/stopped_list",
    component: IbowAccountStoppedList,
    meta: { authName: "/ibowaccount", is_edit_page: false },
  },
  //FAQ管理
  {
    path: "/faq",
    beforeEnter() {
      window.open("https://www.ewellibow.jp/manual/wp/wp-login.php", "_blank");
    },
  },
  //デモサイト管理
  {
    path: "/demosite/input",
    name: "InputDemosite",
    component: InputDemosite,
    meta: { authName: "/demosite", is_edit_page: false },
  },
  //職員一括連携解除
  {
    path: "/stafflink/release",
    name: "ReleaseStaffLink",
    component: ReleaseStaffLink,
    meta: { authName: "/stafflink", is_edit_page: false },
  },
  //看護計画書AI自動作成管理 データセット一覧
  {
    path: "/ai-vnplan/dataset/list",
    name: "DatasetList",
    component: DatasetList,
    meta: { authName: "/ai-vnplan", is_edit_page: false },
  },
  //看護計画書AI自動作成管理 データセット編集
  {
    path: "/ai-vnplan/dataset/edit/:id",
    name: "DatasetForm",
    component: DatasetForm,
    meta: { authName: "/ai-vnplan", is_edit_page: true },
  },
  //看護計画書AI自動作成管理 プロンプトテンプレート一覧
  {
    path: "/ai-vnplan/prompt/list",
    name: "PromptTemplateList",
    component: PromptTemplateList,
    meta: { authName: "/ai-vnplan", is_edit_page: false },
  },
  //看護計画書AI自動作成管理 プロンプトテンプレート編集
  {
    path: "/ai-vnplan/prompt/edit/:id",
    name: "PromptTemplateForm",
    component: PromptTemplateForm,
    meta: { authName: "/ai-vnplan", is_edit_page: true },
  },
  //看護計画書AI自動作成管理 インデックス設定
  {
    path: "/ai-vnplan/index/configure",
    name: "IndexConfigure",
    component: IndexConfigure,
    meta: { authName: "/ai-vnplan", is_edit_page: false },
  },
  //看護計画書AI自動作成管理 自動作成設定
  {
    path: "/ai-vnplan/configuration",
    name: "Configuration",
    component: Configuration,
    meta: { authName: "/ai-vnplan", is_edit_page: false },
  },
  //AIお勧め検索 一覧
  {
    path: "/recommend/list",
    name: "TermList",
    component: TermList,
    meta: { authName: "/recommend", is_edit_page: false },
  },
  //AIお勧め検索 編集
  {
    path: "/recommend/edit/:id",
    name: "TermEdit",
    component: TermEdit,
    meta: { authName: "/recommend", is_edit_page: false },
  },
  //サービスリンク
  {
    path: "/service_link/list",
    name: "ServiceLinkList",
    component: ServiceLinkList,
    meta: { authName: "/service_link", is_edit_page: false },
  },
  {
    path: "/service_link/edit/:id",
    name: "ServiceLinkEdit",
    component: ServiceLinkEdit,
    meta: { authName: "/service_link", is_edit_page: false },
  },
  {
    path: "/service_link/sort",
    name: "ServiceLinkSort",
    component: ServiceLinkSort,
    meta: { authName: "/service_link", is_edit_page: false },
  },
  {
    path: "/service_link/category",
    name: "ServiceLinkCategory",
    component: ServiceLinkCategory,
    meta: { authName: "/service_link", is_edit_page: false },
  },
  {
    path: "/service_link/mail_log",
    name: "ServiceLinkMailLog",
    component: ServiceLinkMailLog,
    meta: { authName: "/service_link", is_edit_page: false },
  },
  //月次報告書AI自動作成管理 プロンプトテンプレート一覧
  {
    path: "/ai-monthreport/prompt/list",
    name: "AiMonthReportPromptTemplateList",
    component: AiMonthReportPromptTemplateList,
    meta: { authName: "/ai-monthreport", is_edit_page: false },
  },
  //月次報告書AI自動作成管理 プロンプトテンプレート編集
  {
    path: "/ai-monthreport/prompt/edit/:id",
    name: "AiMonthReportPromptTemplateEdit",
    component: AiMonthReportPromptTemplateEdit,
    meta: { authName: "/ai-monthreport", is_edit_page: true },
  },
  //PortalStaff管理者ログイン
  {
    path: "/portallogin/portalstaff-agent-login",
    name: "PortalStaffAgentLogin",
    component: PortalStaffAgentLogin,
    meta: { authName: "/portallogin", is_edit_page: false },
  },
  {
    path: "/portallogin/requestuser-agent-login",
    name: "PortalRequestUserAgentLogin",
    component: PortalRequestUserAgentLogin,
    meta: { authName: "/portallogin", is_edit_page: false },
  },
  //Portal管理
  {
    path: "/portal/portal-office/list",
    name: "PortalOfficeList",
    component: PortalOfficeList,
    meta: { authName: "/portal", is_edit_page: false },
  },
  {
    path: "/portal/portal-office/unapproved_list",
    name: "PortalOfficeUnapprovedList",
    component: PortalOfficeUnapprovedList,
    meta: { authName: "/portal", is_edit_page: false },
  },
  {
    path: "/portal/portal-office-ibow/detail/:id",
    name: "PortalOfficeDetail",
    component: PortalOfficeIbowDetail,
    meta: { authName: "/portal", is_edit_page: false },
  },
  {
    path: "/portal/portal-office-no-ibow/detail/:id",
    name: "PortalOfficeNoIbowDetail",
    component: PortalOfficeNoIbowDetail,
    meta: { authName: "/portal", is_edit_page: false },
  },
  {
    path: "/portal/portal-office-ibow/edit/:id",
    name: "PortalOfficeIbowEdit",
    component: PortalOfficeIbowEdit,
    meta: { authName: "/portal", is_edit_page: false },
  },
  {
    path: "/portal/portal-office-no-ibow/edit/:id",
    name: "PortalOfficeNoIbowEdit",
    component: PortalOfficeNoIbowEdit,
    meta: { authName: "/portal", is_edit_page: false },
  },
  {
    path: "/portal/portal-agreement-no-ibow/edit/:id",
    name: "PortalAgreementNoIbowEdit",
    component: PortalAgreementNoIbowEdit,
    meta: { authName: "/portal", is_edit_page: false },
  },
  {
    path: "/portal/organization/list",
    name: "PortalOrganizationList",
    component: PortalOrganizationList,
    meta: { authName: "/portal", is_edit_page: false },
  },
  {
    path: "/portal/organization/unapproved_list",
    name: "PortalOrganizationUnapprovedList",
    component: PortalOrganizationUnapprovedList,
    meta: { authName: "/portal", is_edit_page: false },
  },
  {
    path: "/portal/organization/detail/:id",
    name: "PortalOrganizationDetail",
    component: PortalOrganizationDetail,
    meta: { authName: "/portal", is_edit_page: false },
  },
  {
    path: "/portal/organization/edit/:id",
    name: "PortalOrganizationEdit",
    component: PortalOrganizationEdit,
    meta: { authName: "/portal", is_edit_page: true },
  },
  {
    path: "/portal/mail-log/list",
    name: "PortalMailLog",
    component: PortalMailLog,
    meta: { authName: "/portal", is_edit_page: false },
  },
  //Portal管理
  {
    path: "/portal-announcement/list",
    name: "PortalOfficialAnnouncementList",
    component: PortalOfficialAnnouncementList,
    meta: { authName: "/portal-announcement", is_edit_page: false },
  },
  {
    path: "/portal-announcement/edit/:id",
    name: "PortalOfficialAnnouncementEdit",
    component: PortalOfficialAnnouncementEdit,
    meta: { authName: "/portal-announcement", is_edit_page: true },
  },
  // 請求お知らせ管理
  {
    path: "/invoicenotice/current",
    name: "InvoiceNoticeCurrentList",
    component: InvoiceNoticeCurrentList,
    meta: { authName: "/invoicenotice", is_edit_page: false },
  },
  {
    path: "/invoicenotice/end",
    name: "InvoiceNoticeEndList",
    component: InvoiceNoticeEndList,
    meta: { authName: "/invoicenotice", is_edit_page: false },
  },
  {
    path: "/invoicenotice/edit/:id",
    name: "InvoiceNoticeForm",
    component: InvoiceNoticeForm,
    meta: { authName: "/invoicenotice", is_edit_page: true },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;
