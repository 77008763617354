







































































import { Component, Mixins, Ref } from "vue-property-decorator";
import TrumbowygEditor from "@/components/trumbowyg/TrumbowygEditor.vue";
import { VForm } from "@/types";
import { InvoiceNotice, DEFAULT_INVOICE_NOTICE } from "#/model/bulletinboard";
import AppAuthButton from "@/components/admin_common/AppAuthButton.vue";
import AxiosMixin from "@/mixins/axiosMixin";
import RulesMixin from "#/mixins/rulesMixin";
import UtilMixin from "@/mixins/utilMixin";

@Component({
  components: {
    TrumbowygEditor,
    AppAuthButton,
  },
})
export default class Form extends Mixins(AxiosMixin, UtilMixin, RulesMixin) {
  /** Ref */

  /** フォームバリデーション用 */
  @Ref("form") private readonly form!: VForm;

  /** 変数 */

  /** 請求お知らせ */
  private invoiceNotice: InvoiceNotice = DEFAULT_INVOICE_NOTICE;

  /** getter */

  private get PageTitle(): string {
    return this.invoiceNotice.id == 0
      ? "請求お知らせ新規作成"
      : "請求お知らせ編集";
  }

  /** 関数 */

  public created(): void {
    this.fetchInvoiceNotice();
  }

  /** お知らせ取得 */
  private fetchInvoiceNotice() {
    const invoiceNoticeId = Number(this.$route.params.id);

    this.postJsonCheck(
      window.base_url + "/api/admin/invoicenotice/get",
      {
        id: invoiceNoticeId,
      },
      (res) => {
        this.invoiceNotice = res.data.invoice_notice;
        // 編集する場合は必ずステータスを通常にする
        this.invoiceNotice.status = 1;
      }
    );
  }

  /** 請求お知らせ保存 */
  private async save() {
    if (!this.form.validate()) {
      await this.$openAlert("入力内容に不備があります。");
      return;
    }

    if (!(await this.$openConfirm("保存しますか?"))) {
      return;
    }

    this.postJsonCheck(
      window.base_url + "/api/admin/invoicenotice/save",
      {
        invoice_notice: this.invoiceNotice,
      },
      () => {
        this.$router.push({ path: "/invoicenotice/current" });
      }
    );
  }

  /** 請求お知らせ削除 */
  private async deleteInvoiceNotice() {
    if (!(await this.$openConfirm("削除します。よろしいですか？"))) {
      return;
    }

    this.postJsonCheck(
      window.base_url + "/api/admin/invoicenotice/delete",
      {
        invoice_notice: this.invoiceNotice,
      },
      () => {
        this.$router.push({ path: "/invoicenotice/current" });
      }
    );
  }

  /** キャンセル */
  private async cancel() {
    if (
      await this.$openConfirm(
        "編集を終了します。\n編集中の場合は内容が破棄されます。"
      )
    ) {
      this.$router.push({ path: "/invoicenotice/current" });
    }
  }
}
