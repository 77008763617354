



































import { Component, Prop, Vue } from "vue-property-decorator";
import {
  BillingEwellUser,
  DEFAULT_BILLING_EWELL_USER,
} from "@/model/agreement/billing";

@Component({})
export default class OfficeInfo extends Vue {
  /** Prop */

  /** 請求情報公開先iBowアカウント情報 */
  @Prop({ default: () => DEFAULT_BILLING_EWELL_USER })
  billingEwellUser!: BillingEwellUser;
}
