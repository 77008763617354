








































import { Component, Mixins } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import { Office } from "@/model/office";
import { BillingFile, DEFAULT_BILLING } from "@/model/agreement/billing";
import { PortalOffice } from "@/model/portal";

@Component
export default class PortalSelectOfficeList extends Mixins(AxiosMixin) {
  /** 法人ID */
  private agreementId = 0;

  /** 事業所ID */
  private officeId = 0;

  /** 請求情報 */
  private billing = { ...DEFAULT_BILLING };

  /** 検索文字列 */
  private search_keyword = "";

  /** 連携中事業所 */
  private now_portal_office = { id: 0, name: "" };

  /** テーブルヘッダ */
  private headers = [
    {
      text: "けあログっと事業所名",
      value: "name",
    },
    {
      text: "事業所番号",
      value: "office_code",
    },
    {
      text: "郵便番号",
      value: "post_code",
    },
  ];

  /** テーブルデータ */
  private items = [];

  public created(): void {
    this.agreementId = Number(this.$route.params.agreement_id);
    this.officeId = Number(this.$route.params.id);
    this.fetch();
  }

  // APIアクセス 事業所グループ一覧取得
  private async fetch() {
    const res = await Promise.all([
      this.getPortalOffices(),
      this.getAgreement(),
    ]);
    for (const item of res[0]) {
      if (item.id === res[1]) {
        this.now_portal_office = { id: item.id, name: item.name || "" };
        break;
      }
    }
  }

  // APIアクセス 事業所グループ一覧取得
  private getPortalOffices(): Promise<PortalOffice[]> {
    return new Promise((resolve) => {
      this.postJsonCheck(
        window.base_url + "/api/admin/portal_offices/get",
        {},
        (res) => {
          // 事業所IDが0のもの(未連携のもの)を取得
          if (res.data.all_portal_offices) {
            this.items = res.data.all_portal_offices.filter(
              (item: { ibow_office_id: number }) => item.ibow_office_id === 0
            );
          }
          resolve(res.data.all_portal_offices);
        }
      );
    });
  }

  // APIアクセス 事業所グループ一覧取得
  private getAgreement(): Promise<number> {
    return new Promise((resolve) => {
      this.postJsonCheck(
        window.base_url + "/api/admin/agreements/get",
        {
          agreement_id: this.agreementId,
          ids: [this.agreementId],
          is_display_confirm: 1,
        },
        (res) => {
          const office = res.data.agreements[0].offices.find(
            (o: Office) => o.id == Number(this.officeId)
          );
          this.billing = office.billing_info;
          if (!this.billing.billing_files) {
            this.billing.billing_files = [] as BillingFile[];
          }
          this.billing.change_message = "";
          resolve(this.billing.portal_office_id);
        }
      );
    });
  }

  // APIアクセス けあログID保存
  private async clickRow({ id }: { id: number }) {
    if (!(await this.$openConfirm("利用開始時:データ連携元けあログっと事業所と連携しますか？"))) {
      return;
    }
    this.savePortalOffice(id);
  }

  // APIアクセス けあログID解除
  private async clearPortalOffice() {
    if (!(await this.$openConfirm("利用開始時:データ連携元けあログっと事業所との連携を解除しますか？"))) {
      return;
    }
    this.savePortalOffice(0);
  }

  private savePortalOffice(id: number) {
    this.billing.portal_office_id = id;
    this.postJsonCheck(
      window.base_url + "/api/admin/billing_info/save",
      {
        billing: this.billing,
        agreement_id: Number(this.agreementId),
        saving_confflag: 1,
      },
      () => {
        this.$router.go(-1);
      }
    );
  }
}
