import { Menu } from "@/types";

export const SIDE_MENUS: Menu[] = [
  {
    id: 1,
    title: "お知らせ管理",
    active: false,
    path: "/information",
    items: [
      {
        id: 1,
        title: "公開前／公開中",
        url: "/information/current",
        is_edit_page: false,
      },
      {
        id: 2,
        title: "公開終了",
        url: "/information/end",
        is_edit_page: false,
      },
      {
        id: 3,
        title: "新規作成",
        url: "/information/form/0",
        is_edit_page: true,
      },
    ],
  },
  {
    id: 2,
    title: "サポート管理",
    active: false,
    path: "/inquiry",
    items: [
      {
        id: 2,
        title: "問い合わせ進行中",
        url: "/inquiry/inprogress",
        is_edit_page: false,
      },
      {
        id: 3,
        title: "問い合わせ完了",
        url: "/inquiry/completed",
        is_edit_page: false,
      },
      {
        id: 1,
        title: "新規作成",
        url: "/inquiry/0/edit",
        is_edit_page: true,
      },
      {
        id: 4,
        title: "iBowヘルプ(テンプレート)",
        url: "/template",
        is_edit_page: false,
      },
      {
        id: 5,
        title: "おすすめキーワード",
        url: "/template/recommendkeyword",
        is_edit_page: true,
      },
      {
        id: 6,
        title: "iBowヘルプ並び替え",
        url: "/template/sort",
        is_edit_page: false,
      },
    ],
  },
  {
    id: 3,
    title: "契約管理",
    active: false,
    path: "/contract",
    items: [
      {
        id: 1,
        title: "契約管理企業",
        url: "/contractmanagement/list",
        is_edit_page: false,
      },
      {
        id: 2,
        title: "未確定契約一覧",
        url: "/contractreception/list",
        is_edit_page: false,
      },
    ],
  },
  {
    id: 4,
    title: "メンテ設定",
    active: false,
    path: "/maintenance",
    items: [
      {
        id: 1,
        title: "公開前/公開中",
        url: "/maintenance/notification",
        is_edit_page: false,
      },
      {
        id: 2,
        title: "公開終了",
        url: "/maintenance/finish",
        is_edit_page: false,
      },
      {
        id: 3,
        title: "新規作成",
        url: "/maintenance/edit/0",
        is_edit_page: true,
      },
    ],
  },
  {
    id: 5,
    title: "アンケート管理",
    active: false,
    path: "/research",
    items: [
      {
        id: 1,
        title: "公開前/公開中",
        url: "/research/current",
        is_edit_page: false,
      },
      {
        id: 2,
        title: "公開終了",
        url: "/research/end",
        is_edit_page: false,
      },
      {
        id: 3,
        title: "新規作成",
        url: "/research/edit/0",
        is_edit_page: true,
      },
    ],
  },
  {
    id: 6,
    title: "基本設定",
    active: false,
    path: "/configuration",
    items: [
      {
        id: 1,
        title: "基本設定",
        url: "/configuration/show",
        is_edit_page: false,
      },
      {
        id: 2,
        title: "エクスポート",
        url: "/configuration/export",
        is_edit_page: false,
      },
      {
        id: 3,
        title: "集計情報",
        url: "/configuration/viewer",
        is_edit_page: false,
      },
    ],
  },
  {
    id: 7,
    title: "iBowログイン",
    active: false,
    path: "/ibowlogin",
    items: [
      {
        id: 1,
        title: "iBowにログイン",
        url: "/ibowlogin/login",
        is_edit_page: false,
      },
      {
        id: 2,
        title: "iBowユーザとしてログイン",
        url: "/ibowlogin/spoofing",
        is_edit_page: false,
      },
    ],
  },
  {
    id: 8,
    title: "ユーザー管理",
    active: false,
    path: "/user",
    items: [
      {
        id: 1,
        title: "一覧",
        url: "/user/list",
        is_edit_page: false,
      },
      {
        id: 2,
        title: "権限一覧",
        url: "/auth/list",
        is_edit_page: false,
      },
    ],
  },
  {
    id: 9,
    title: "オペレーションログ",
    active: false,
    path: "/operationlog",
    items: [
      {
        id: 1,
        title: "iBow2オペレーションログ",
        url: "/operationlog/show",
        is_edit_page: false,
      },
      {
        id: 2,
        title: "管理者オペレーションログ",
        url: "/admin/operationlog/show",
        is_edit_page: false,
      },
    ],
  },
  {
    id: 15,
    title: "デモサイト管理",
    active: false,
    path: "/demosite",
    items: [
      {
        id: 1,
        title: "一括作成",
        url: "/demosite/input",
        is_edit_page: false,
      },
    ],
  },
  {
    id: 16,
    title: "職員一括連携解除",
    active: false,
    path: "/stafflink",
    items: [
      {
        id: 1,
        title: "職員一括連携解除",
        url: "/stafflink/release",
        is_edit_page: false,
      },
    ],
  },
  {
    id: 10,
    title: "iBowアカウント管理",
    active: false,
    path: "/ibowaccount",
    items: [
      {
        id: 1,
        title: "一覧",
        url: "/ibowaccount/list",
        is_edit_page: false,
      },
      {
        id: 2,
        title: "復元/消去",
        url: "/ibowaccount/deleted_list",
        is_edit_page: false,
      },
      {
        id: 3,
        title: "停止解除",
        url: "/ibowaccount/stopped_list",
        is_edit_page: false,
      },
    ],
  },
  {
    id: 17,
    title: "AIお勧め検索管理",
    active: false,
    path: "/recommend",
    items: [
      {
        id: 1,
        title: "一覧",
        url: "/recommend/list",
        is_edit_page: false,
      },
    ],
  },
  {
    id: 18,
    title: "サービスリンク",
    active: false,
    path: "/service_link",
    items: [
      {
        id: 1,
        title: "一覧",
        url: "/service_link/list",
        is_edit_page: false,
      },
      {
        id: 2,
        title: "並び替え",
        url: "/service_link/sort",
        is_edit_page: false,
      },
      {
        id: 3,
        title: "カテゴリ",
        url: "/service_link/category",
        is_edit_page: false,
      },
      {
        id: 4,
        title: "お問合せログ",
        url: "/service_link/mail_log",
        is_edit_page: false,
      },
    ],
  },
  {
    id: 19,
    title: "看護計画書AI自動作成管理",
    active: false,
    path: "/ai-vnplan",
    items: [
      {
        id: 1,
        title: "データセット",
        url: "/ai-vnplan/dataset/list",
        is_edit_page: false,
      },
      {
        id: 2,
        title: "プロンプトテンプレート",
        url: "/ai-vnplan/prompt/list",
        is_edit_page: false,
      },
      {
        id: 3,
        title: "インデックス設定",
        url: "/ai-vnplan/index/configure",
        is_edit_page: false,
      },
      {
        id: 4,
        title: "自動作成設定",
        url: "/ai-vnplan/configuration",
        is_edit_page: false,
      },
    ],
  },
  {
    id: 20,
    title: "月次報告書AI自動作成管理",
    active: false,
    path: "/ai-monthreport",
    items: [
      {
        id: 1,
        title: "プロンプトテンプレート",
        url: "/ai-monthreport/prompt/list",
        is_edit_page: false,
      },
    ],
  },
  {
    id: 21,
    title: "けあログっとログイン",
    active: true,
    path: "/portallogin",
    items: [
      {
        id: 1,
        title: "けあログっと掲載側職員でログイン",
        url: "/portallogin/portalstaff-agent-login",
        is_edit_page: false,
      },
      {
        id: 2,
        title: "けあログっと紹介側職員でログイン",
        url: "/portallogin/requestuser-agent-login",
        is_edit_page: false,
      },
    ],
  },
  {
    id: 22,
    title: "けあログっと管理",
    active: true,
    path: "/portal",
    items: [
      {
        id: 1,
        title: "事業所一覧",
        url: "/portal/portal-office/list",
        is_edit_page: false,
      },
      {
        id: 1,
        title: "未承認事業所一覧",
        url: "/portal/portal-office/unapproved_list",
        is_edit_page: false,
      },
      {
        id: 2,
        title: "所属一覧",
        url: "/portal/organization/list",
        is_edit_page: false,
      },
      {
        id: 2,
        title: "未承認所属一覧",
        url: "/portal/organization/unapproved_list",
        is_edit_page: false,
      },
      {
        id: 3,
        title: "けあログっとメール送信ログ",
        url: "/portal/mail-log/list",
        is_edit_page: false,
      },
    ],
  },
  {
    id: 23,
    title: "けあログっと運営からのお知らせ",
    active: true,
    path: "/portal-announcement",
    items: [
      {
        id: 1,
        title: "けあログっと運営からのお知らせ",
        url: "/portal-announcement/list",
        is_edit_page: true,
      },
    ],
  },
  {
    id: 24,
    title: "請求お知らせ管理",
    active: false,
    path: "/invoicenotice",
    items: [
      {
        id: 1,
        title: "公開前／公開中",
        url: "/invoicenotice/current",
        is_edit_page: false,
      },
      {
        id: 2,
        title: "公開終了",
        url: "/invoicenotice/end",
        is_edit_page: false,
      },
      {
        id: 3,
        title: "新規作成",
        url: "/invoicenotice/edit/0",
        is_edit_page: true,
      },
    ],
  },
];
