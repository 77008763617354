var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.PageTitle))]),_c('v-card-text',[_c('v-form',{ref:"form"},[_c('v-subheader',{staticClass:"pl-0"},[_vm._v("掲載期間")]),_c('v-row',{attrs:{"no-gutters":""}},[_c('AppDayPicker',{staticClass:"mt-2 mb-2",attrs:{"label":"開始日","rules":[_vm.required(_vm.invoiceNotice.start_date, '必須項目です。')]},model:{value:(_vm.invoiceNotice.start_date),callback:function ($$v) {_vm.$set(_vm.invoiceNotice, "start_date", $$v)},expression:"invoiceNotice.start_date"}}),_c('div',{staticClass:"ml-1 mt-3 mr-1"},[_vm._v("〜")]),_c('AppDayPicker',{staticClass:"mt-2 mb-2",attrs:{"label":"終了日","rules":[
              _vm.required(_vm.invoiceNotice.end_date, '必須項目です。'),
              _vm.dateAfter(
                _vm.invoiceNotice.start_date,
                _vm.invoiceNotice.end_date,
                true,
                '掲載終了日は掲載開始日以降を選択してください。'
              ) ]},model:{value:(_vm.invoiceNotice.end_date),callback:function ($$v) {_vm.$set(_vm.invoiceNotice, "end_date", $$v)},expression:"invoiceNotice.end_date"}})],1),_c('v-subheader',{staticClass:"pl-0"},[_vm._v("件名")]),_c('AppTextField',{staticClass:"my-2",attrs:{"label":"件名","width":"100%","rules":[_vm.required(_vm.invoiceNotice.title, '必須項目です。')],"maxlength":"200","counterNum":200},model:{value:(_vm.invoiceNotice.title),callback:function ($$v) {_vm.$set(_vm.invoiceNotice, "title", $$v)},expression:"invoiceNotice.title"}}),_c('v-subheader',{staticClass:"pl-0"},[_vm._v("内容")]),_c('TrumbowygEditor',{attrs:{"placeholder":"内容","required":true},model:{value:(_vm.invoiceNotice.content),callback:function ($$v) {_vm.$set(_vm.invoiceNotice, "content", $$v)},expression:"invoiceNotice.content"}})],1)],1),_c('v-card-actions',{staticClass:"ml-2"},[_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary"},on:{"click":_vm.save}},[_vm._v("保存")]),(_vm.invoiceNotice.id)?_c('v-btn',{staticClass:"mr-2",attrs:{"color":"alert","dark":""},on:{"click":function($event){$event.stopPropagation();return _vm.deleteInvoiceNotice.apply(null, arguments)}}},[_vm._v("削除")]):_vm._e(),_c('v-btn',{on:{"click":_vm.cancel}},[_vm._v("キャンセル")])],1)],1),_c('AppLoadingDialog',{attrs:{"loading":_vm.loading}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }