







































































import { Component, Prop, Mixins, Emit } from "vue-property-decorator";
import { Billing, DEFAULT_BILLING } from "@/model/agreement/billing";
import { EwellUser } from "@/model/ewellUser";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";

@Component({})
export default class AddBillingEwellUserDialog extends Mixins(
  UtilMixin,
  AxiosMixin
) {
  /** Prop */

  /** 請求情報 */
  @Prop({ default: () => DEFAULT_BILLING }) billing_info!: Billing;

  /** ダイアログ表示制御 */
  @Prop({ default: () => false }) value!: boolean;

  /** getter */

  /** ダイアログ表示制御 */
  private get Value(): boolean {
    return this.value;
  }

  /** setter */

  /** ダイアログ表示制御 */
  private set Value(newValue: boolean) {
    this.input(newValue);
  }

  /** ewellユーザー情報 */
  private ewellUsers: EwellUser[] = [];

  /** 絞込み用条件 */
  private searchCondition = {
    name: "",
    keyword: "",
  };

  private headers = [
    {
      text: "",
      align: "center",
      sortable: false,
      value: "actions",
    },
    {
      text: "iBowID",
      sorttable: true,
      value: "user_id",
    },
    {
      text: "氏名",
      sorttable: true,
      value: "name",
    },
    {
      text: "ふりがな",
      sorttable: true,
      value: "furigana",
    },
    {
      text: "メールアドレス",
      sorttable: false,
      value: "mail_address",
    },
    {
      text: "携帯電話番号",
      sorttable: false,
      value: "tel_no",
    },
    {
      text: "システムID",
      sorttable: false,
      value: "system_id",
    },
  ];

  /** iBowアカウント取得 */
  private fetchIbowAccounts() {
    if (this.searchCondition.name != "" || this.searchCondition.keyword) {
      this.postJsonCheck(
        window.base_url + "/api/ibowaccounts/get",
        {
          name: this.searchCondition.name,
          keyword: this.searchCondition.keyword,
        },
        (res) => {
          this.ewellUsers = res.data.ewell_users;
        }
      );
    } else {
      alert("全件検索は出来ません 検索ワードを入力して下さい。");
    }
  }

  /** ダイアログキャンセル */
  private cancel() {
    this.Value = false;
    this.reset();
  }

  /** 情報リセット */
  private reset() {
    this.searchCondition = { name: "", keyword: "" };
    this.ewellUsers = [];
  }

  /** Emit */

  /** 更新 */
  @Emit()
  private input(newValue: boolean): boolean {
    return newValue;
  }

  /** 追加 */
  @Emit()
  private add(account: EwellUser) {
    this.Value = false;
    this.reset();
    return account;
  }
}
